import { iModal } from 'typings'
import React, { FC } from 'react'
import Styles from './modal.module.scss'
import { dequal } from 'dequal';
import { diff } from '../../../global/utilities';
import Button from '../../1_atom/buttons/button';
import { iModalCallbackRef } from './modal';

interface ModalContentProps {
  modal: iModal;
  dismissModal: (...args: any[]) => any;
  closeModal: (...args: any[]) => any;
  modalCallbackRef: iModalCallbackRef;
}

const ModalContent: FC<ModalContentProps> = ({
    modal,
    modal: {
      top,
      middle,
      bottom,
      confirmCallback,
      middlePadding = true,
      bottomPadding = true,
    },
    dismissModal,
    closeModal,
    modalCallbackRef
  }) => {

  if(!modal) {
    console.error("no modal data");
    return <></>
  }

  const handleClick = () => {
    if (confirmCallback && typeof confirm === 'function') {
      confirmCallback()
    }
    dismissModal()
  }

  const renderComponent = (component: JSX.Element) => {
    return React.cloneElement(component, {
      dismissModal,
      closeModal,
      modal,
      modalCallbackRef
    })
  }

  const TopContent = () => {
    if(!top) return <></>
    if(typeof top === "string") {
      return (
        <div className={`${Styles.modalTop} left-right-container`}>
          <h4 className={Styles.modalTitle}>{top}</h4>
          <Button
            size="mini-sq"
            variant="transparent"
            color="white-on-dark"
            iconLeft={{
              icon: "cross-two",
              fill: false,
            }}
            click={() => closeModal()}
          />
        </div>
      );
    }

    return renderComponent(top)
  }

  const MiddleContent = () => {
    if(!middle) return <></>
    if(typeof middle === "string") {
      return (
        <div className={`${Styles.modalMiddle}`}>
          <p className={Styles.intro} style={{ whiteSpace: "pre-line" }}>
            {middle}
          </p>
        </div>
      )
    }
    if(middlePadding){
      return (
        <div className={`${Styles.modalMiddle}`}>
          {renderComponent(middle)}
        </div>
      )
    }
    return renderComponent(middle)
  }

  const BottomContent = () => {
    if(!bottom) return <></>
    if(typeof bottom === "string") {
      return (
        <div className={`${Styles.modalBottom}`}>
          <div className={Styles.submitContainer}>
            <Button size="small" text={bottom} click={handleClick} />
          </div>
        </div>
      );
    }
    if(bottomPadding){
      return (
        <div className={`${Styles.modalBottom}`}>
          {renderComponent(bottom)}
        </div>
      )
    }

    return renderComponent(bottom)
  };

  return (
    <>
      <TopContent />
      <MiddleContent />
      <BottomContent />
    </>
  );
}

const areEqual = (prevProps: any, nextProps: any) => {
  const sameProps = dequal(prevProps, nextProps)
  const diff1 = diff(prevProps, nextProps)
  // console.log({sameProps, prevProps, nextProps, diff1})
  return sameProps
}

export default React.memo(ModalContent, areEqual);
