import React, { FC, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import Styles from "./mainNav.module.scss"
import LoginButton from '../../1_atom/loginButton/loginButton';
import Logo from '../../0_global/logo';
import Button, { iButtonIcon } from '../../1_atom/buttons/button';
import { iMenuItem } from 'typings';
import { useAuth } from '../../../context/authContext';
import { useContextSelector } from 'use-context-selector';
import Context from '../../../context/MainContext';
import { popOverState } from '../../../global/state/global';
import { useSetAtom } from 'jotai';

interface MainNavProps { }
type navItem = {
  label: string
  path: string
  icon?: iButtonIcon
}

const MainNav: FC<MainNavProps> = ({ }) => {
  
  const setDomRect = useSetAtom(popOverState)
  const navButtons = useContextSelector(Context, state => state.navButtons);
  const auth = useAuth()
  const router = useRouter()
  const [isExpanded, setIsExpanded] = useState(false)

  const isRoute = (checkRoute: string) => {
    if(!router) return false
    return router.pathname.startsWith(checkRoute)
  }

  const getActiveStyleIfRoute = (route: string) => {
    if(isRoute(route)) return "soft"
    return "transparent"
  }

  const navItems: navItem[] = [
    {
      label: "Signups",
      path: "/signups",
      icon: {
        icon: "event-confirm",
        fill: true,
        title: "Signups"
      }
    },
    {
      label: "Teams",
      path: "/teams",
      icon: {
        icon: "team",
        fill: true,
        title: "Teams",
      },
    },
    {
      label: "Automations",
      path: "/automations",
      icon: {
        icon: "robot",
        fill: true,
        title: "Automations",
      }
    },
    {
      label: "Discord bot",
      path: "/bot",
      icon: {
        icon: "discord",
        fill: true,
        title: "Discord bot",
        addClass: "center-center"
      }
    },
    {
      label: "Pricing",
      path: "/pricing",
      icon: {
        icon: "star",
        fill: false,
        title: "Pricing"
      }
    },
    {
      label: "Settings & Characters",
      path: "/settings",
      icon: {
        icon: "settings",
        fill: true,
        title: "Settings & Characters"
      }
    },
  ]

  const NavItem = ({ navItem }: { navItem: navItem }) => {
    return (
      <Link href={navItem.path} className={`${Styles.navItem} center-center`}>
        <Button
          size="mini-sq"
          textAlign="left"
          variant={getActiveStyleIfRoute(navItem.path)}
          text={navItem.label}
          addClass={`${Styles.navItemBtn} clickable`}
          color={getActiveStyleIfRoute(navItem.path) ? "white-on-dark" : ""}
          iconLeft={navItem.icon}
          click={() => {
            setIsExpanded(false)
          }}
        />
      </Link>
    )
  }

  const Top = () => {
    return (
      <div className={`${Styles.navTop}`}>
        <div className={Styles.logo}>
          <Link href="/">
            <Logo />
          </Link>
          <div className={`${Styles.betaTag}`}>
            <span>beta</span>
          </div>
        </div>
      </div>
    )
  }

  const Middle = () => {
    return (
      <div className={Styles.navMid}>
        <div className={`flex-row gap0 hideBelowDesktop`}>
          {navItems.map((navItem) => (
            <NavItem key={navItem.label} navItem={navItem} />
          ))}
        </div>
        <div className="hideChildrenOnMobile flex-row gap1">
          {navButtons}
        </div>
      </div>
    )
  }

  const Bottom = () => {
    const [enableNoUserUI, setEnableNoUserUI] = useState(false)

    const menuItems: iMenuItem[] = [
      {
        desc: "Logout",
        click: auth?.signout,
        icon: {
          icon: "logout",
          fill: true,
          title: "Logout",
        },
      },
    ]

    const MobileHamburger = () => {
      return (
        <div className={`flex-row hideOnDesktop`}>
          <Button
            size="mini-sq"
            variant="transparent"
            color="white-on-dark"
            click={() => {
              setIsExpanded(!isExpanded)
            }}
            iconLeft={{
              icon: "menu",
              title: "Menu",
              strokeWidth: 2
            }}
          />
        </div>
      )
    }

    useEffect(() => {
      if(auth?.user === null) setEnableNoUserUI(true)
    }, [auth?.user])
    
    if(enableNoUserUI) return (
      <div className={`${Styles.navBottom}`}>
        <LoginButton mini />
        <MobileHamburger />
      </div>
    )

    return (
      <div className={`${Styles.navBottom}`}>
        <div className="pos-rel hideBelowDesktop">
          <Button
            size="mini-sq"
            variant="transparent"
            color="white-on-dark"
            click={(event) => {
              setDomRect({
                domRect: event.target?.getBoundingClientRect(),
                side: "bottom",
                content: {
                  mode: "menu",
                  menuItems
                },
                options: {
                  zIndex: 99,
                  modal: true
                }
              });
            }}
            iconLeft={{
              icon: "dots",
              title: "Menu",
            }}
          />
        </div>
        <MobileHamburger />
      </div>
    )
  }

  const MobileMenu = () => {

    return (
      <div className={`${Styles.mobileMenu} ${isExpanded ? "" : "hidden"} flex-col p1 gap1 bg2`}>
        {navItems.map(navItem => <NavItem key={navItem.label} navItem={navItem} />)}
        <LoginButton showLogout={true} fullWidth />
      </div>
    )
  }
  
  return (
    <div id="navigator" className={Styles.navContainer}>
      <div className={`${Styles.nav}`}>
        <div className={Styles.maxWidthContainer}>
          <Top />
          <Middle />
          <Bottom />
        </div>
      </div>
      <MobileMenu />
    </div>
  );
}

export default MainNav;