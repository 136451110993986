import { ErrorBoundary } from '@sentry/nextjs';
import { useSetAtom } from 'jotai';
import Head from "next/head";
import { useRouter } from 'next/router';
import React, { FC, useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { useContextSelector } from 'use-context-selector';
import Context from '../../../context/MainContext';
import { useAuth } from '../../../context/authContext';
import { LocationProvider } from '../../../context/locationContext';
import { lastRouterPathState, loginRedirectURIState } from '../../../global/state/global';
import Footer from '../footer/footer';
import Modal from '../modal/modal';
import MainNav from '../nav/mainNav';
import Styles from './layout.module.scss';

interface LayoutProps {
  children: React.ReactNode
  maxLayoutWidth?: "narrow" | "medium" | "normal" | "max"
}

export const WidthConstraint: FC<LayoutProps> = ({ children, maxLayoutWidth }) => {
  return (
    <div className={`${Styles.pageContent} ${Styles[`${maxLayoutWidth}-width`]}`}>{children}</div>
  )
}

const Layout: FC<LayoutProps> = ({ children, maxLayoutWidth = "normal" }) => {
  
  const theme = useContextSelector(Context, state => state.theme);
  const router = useRouter()
  const auth = useAuth()
  
  const setOrigin = useSetAtom(loginRedirectURIState)
  const setLastRouterPath = useSetAtom(lastRouterPathState)
  
  useEffect(() => {
    const origin = encodeURIComponent(window.location.origin + "/login")
    setOrigin(origin)
  }, [])

  useEffect(() => {
    if(!router?.asPath || router.pathname === "/login") return
    setLastRouterPath(router.asPath)
  }, [router])

  return (
    <ErrorBoundary
      showDialog
      dialogOptions={{
        user: {
          name: auth?.userData?.discordUID,
          email: "not@necessary.com"
        }
      }}
    >
      <div className={Styles.pageContainer}>
        <Head>
          <meta name="theme-color" content={theme === "dark" ? "#23252E" : "#F6F6F6"} />
        </Head>
        <Toaster
          position="top-center"
          reverseOrder={false}
          toastOptions={{
            success: {
              duration: 3000
            },
            error: {
              duration: 6000
            }
          }}
        />

        <LocationProvider>
          <Modal />
        </LocationProvider>

        <MainNav />
        <div className={`${Styles.pageContent} ${Styles[`${maxLayoutWidth}-width`]}`}>
          {children}
        </div>
        <Footer />
      </div>
    </ErrorBoundary>
  )
};

export default Layout