import Styles from './footer.module.scss';
import Link from 'next/link';

const Footer = () => {
  return (
    <div className={`${Styles.footer} bg2`}>
      <div className="flex-row wrap gap2">
        <Link href="/news" prefetch={false} className={`${Styles.link}`}>
          News
        </Link>
        <Link href="/faq" prefetch={false} className={`${Styles.link}`}>
          FAQ
        </Link>
        <Link href="/documentation" prefetch={false} className={`${Styles.link}`}>
          Documentation
        </Link>
        <Link href="/pricing" prefetch={false} className={`${Styles.link}`}>
          Pricing
        </Link>
        <Link
          href="https://discord.gg/zGCmJtbHJz"
          prefetch={false}
          className={`${Styles.link}`}>
          Prepi Discord server
        </Link>
        <Link href="/privacy" prefetch={false} className={`${Styles.link}`}>
          Privacy Policy
        </Link>
        <Link href="/tos" prefetch={false} className={`${Styles.link}`}>
          Terms of Service
        </Link>
        <Link href="/cookie" prefetch={false} className={`${Styles.link}`}>
          Cookie Policy
        </Link>
      </div>
    </div>
  );
}

export default Footer;