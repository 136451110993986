import { useOnClickOutside } from '../../../global/hooks'
import React, { useRef, useCallback, MutableRefObject } from 'react'
import { CSSTransition } from "react-transition-group";
import ModalContent from './modalContent'
import Styles from './modal.module.scss'
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { checkCanDismissModalAtom, modalState, popOverState, showModalState } from '../../../global/state/global';
import { jotaiStore } from '../../../pages/_app';
import { classNames } from 'functions';

export type iModalCallbacks = { [key: string]: (...args: any[]) => any };
export type iModalCallbackRef = MutableRefObject<iModalCallbacks>;

const Modal = () => {

  const [showModal, setShowModal] = useAtom(showModalState)
  const modal = useAtomValue(modalState)
  const checkCanDismiss = useSetAtom(checkCanDismissModalAtom)
  const innerRef = useRef(null);
  const isEntered = useRef(showModal);
  const modalCallbackRef = useRef<iModalCallbacks>({})
  
  useOnClickOutside(innerRef, () => {
    if(!isEntered.current) return
    if(!!jotaiStore.get(popOverState)) return
    if(checkCanDismiss("clickoutside")) dismissModal();
  })

  const dismissModal = useCallback(() => {
    const canDismiss = checkCanDismiss("all")
    if(canDismiss === false) return
    isEntered.current = false
    setShowModal(false)
  }, [])

  const closeModal = useCallback(async () => {
    const canDismiss = checkCanDismiss("close")
    if(canDismiss === false) return
    if (modal?.allowCloseCallback && typeof modal?.allowCloseCallback === 'function') {
      try {
        // will only dismiss modal if onClose returns false
        // if it does not return anything or true, it will close
        const allowDismissModal = await modal?.allowCloseCallback()
        if (allowDismissModal === false) return
      } catch (error) {
        console.log(error)
      }
    }
    dismissModal()
  }, [])

  return (
    <CSSTransition
      in={showModal}
      timeout={300}
      classNames={{
        appear: Styles.backdropAppear,
        enter: Styles.backdropEnter,
        enterActive: Styles.backdropEnterActive,
        enterDone: Styles.backdropEnterDone,
        exit: Styles.backdropExit,
        exitActive: Styles.backdropExitActive,
        exitDone: Styles.backdropExitDone
      }}
      onEntered={() => (isEntered.current = true)}
      onExited={() => (isEntered.current = false)}
    >
      <div
        id="modal-container"
        className={classNames(
          Styles.modalContainer,
          modal?.options?.alignVertical === "start" && Styles.justifyStart
        )}
      >
        <CSSTransition
          in={showModal}
          timeout={300}
          classNames={{
            enter: Styles.modalEnter,
            enterActive: Styles.modalEnterActive,
            exit: Styles.modalExit,
            exitActive: Styles.modalExitActive
          }}
          unmountOnExit
        >
          <div className={Styles.paddingContainer}>
            <div
              ref={innerRef}
              className={classNames(
                Styles.modal,
                modal?.options?.size && Styles[modal?.options?.size],
              )}
            >
              <ModalContent
                modal={modal}
                dismissModal={dismissModal}
                closeModal={closeModal}
                modalCallbackRef={modalCallbackRef}
              />
            </div>
          </div>
        </CSSTransition>
      </div>
    </CSSTransition>
  )
}

export default Modal
