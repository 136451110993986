import { getLoginRedirectString } from 'functions';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import { useAuth } from '../../../context/authContext';
import { loginRedirectURIState } from '../../../global/state/global';
import Button from '../buttons/button';

export const PleaseLogin = () => {
  return (
    <div className="please-login-container">
      <h4 className='mb2'>Please login to see the content on this page</h4>
      <LoginButton />
    </div>
  )
}

const LoginButton = ({ showLogout = false, mini = false, fullWidth = false, loginBtnText = "Login with discord" }) => {
  const loginRedirectURI = useAtomValue(loginRedirectURIState)
  const auth = useAuth()
  const [enableLogout, setEnableLogout] = useState(false)

  useEffect(() => {
    if(auth?.user && showLogout) setEnableLogout(true)
  }, [])
  
  if(enableLogout) return (
    <Button
      iconLeft={{
        icon: "logout",
        fill: true,
        title: "Logout",
      }}
      variant="transparent"
      color="white-on-dark"
      text="Logout"
      fullWidth={fullWidth}
      click={auth?.signout}
      size={mini ? "mini-sq" : "small-sq"}
    />
  )

  return (
    <Button
      text={loginBtnText}
      fullWidth={fullWidth}
      color="secondary"
      size={mini ? "mini" : "small"}
      addClass="clickable"
      click={() => {
        window.location.href = getLoginRedirectString({ loginRedirectURI })
      }}
    />
  );
}

export default LoginButton;